.traffic-bottom-content {
    position: relative;
    text-align: center;
    padding-top: 40px;
}
.traffic-bottom-label {
    position: absolute;
    background: #F0F0F0;
    font-weight: 400;
    font-size: 10.4076px;
    color: #172B4D;
    width: 21.95px;
    height: 21.95px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    opacity: 0.2;
    cursor: pointer;
    pointer-events: none;
}
.traffic-bottom-label.green {
    background-color: #52C41A;
    color: #fff;
    opacity: 1;
    pointer-events: auto;
}
.traffic-bottom-label.red {
    background-color: #F5222D;
    color: #fff;
    opacity: 1;
    pointer-events: auto;
}
.traffic-bottom-label.grey {
    background-color: #757575;
    color: #fff;
    opacity: 1;
    pointer-events: auto;
}
.traffic-bottom-label.t1 {
    left: 41px;
    top: 88.43px;
}
.traffic-bottom-label.t2 {
    left: 57.42px;
    top: 54.12px;
}
.traffic-bottom-label.t3 {
    left: 86.37px;
    top: 27.12px;
}
.traffic-bottom-label.t4 {
    left: 125.24px;
    top: 12px;
}
.traffic-bottom-label.t5 {
    left: 163.13px;
    top: 12px;
}
.traffic-bottom-label.t6 {
    left: 201.02px;
    top: 27.12px;
}
.traffic-bottom-label.t7 {
    left: 229.15px;
    top: 54.12px;
}
.traffic-bottom-label.t8 {
    left: 246.39px;
    top: 88.43px;
}
.traffic-bottom-subtract {
    width: 100%;
}
.traffic-bottom-power {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}
.traffic-bottom-hero {
    font-weight: 700;
    font-size: 10.4076px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
    color: #3751FF;
}
.button-cancel {
    border: 0.709368px solid #3751FF;
    width: 86px;
    background: #fff;
    color: #3751FF;
    padding: 4px 0px;
}
.button-apply {
    width: 86px;
    padding: 4px 0px;
}
