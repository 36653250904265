@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyBlack.eot');
  src: url('./assets/fonts/SVN-GilroyBlack.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyBlack.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyBlack.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyBlack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyBlackItalic.eot');
  src: url('./assets/fonts/SVN-GilroyBlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyBlackItalic.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyBlackItalic.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyBlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyBold.eot');
  src: url('./assets/fonts/SVN-GilroyBold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyBold.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyBold.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyBoldItalic.eot');
  src: url('./assets/fonts/SVN-GilroyBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyBoldItalic.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyBoldItalic.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyLight.eot');
  src: url('./assets/fonts/SVN-GilroyLight.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyLight.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyLight.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyItalic.eot');
  src: url('./assets/fonts/SVN-GilroyItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyItalic.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyItalic.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyLightItalic.eot');
  src: url('./assets/fonts/SVN-GilroyLightItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyLightItalic.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyLightItalic.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyLightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyHeavy.eot');
  src: url('./assets/fonts/SVN-GilroyHeavy.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyHeavy.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyHeavy.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyHeavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyHeavyItalic.eot');
  src: url('./assets/fonts/SVN-GilroyHeavyItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyHeavyItalic.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyHeavyItalic.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyHeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyMediumItalic.eot');
  src: url('./assets/fonts/SVN-GilroyMediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyMediumItalic.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyMediumItalic.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyMediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroySemiBoldItalic.eot');
  src: url('./assets/fonts/SVN-GilroySemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroySemiBoldItalic.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroySemiBoldItalic.woff') format('woff'),
      url('./assets/fonts/SVN-GilroySemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyMedium.eot');
  src: url('./assets/fonts/SVN-GilroyMedium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyMedium.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyMedium.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroySemiBold.eot');
  src: url('./assets/fonts/SVN-GilroySemiBold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroySemiBold.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroySemiBold.woff') format('woff'),
      url('./assets/fonts/SVN-GilroySemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-Gilroy.eot');
  src: url('./assets/fonts/SVN-Gilroy.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-Gilroy.woff2') format('woff2'),
      url('./assets/fonts/SVN-Gilroy.woff') format('woff'),
      url('./assets/fonts/SVN-Gilroy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyThinItalic.eot');
  src: url('./assets/fonts/SVN-GilroyThinItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyThinItalic.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyThinItalic.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: url('./assets/fonts/SVN-GilroyThin.eot');
  src: url('./assets/fonts/SVN-GilroyThin.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SVN-GilroyThin.woff2') format('woff2'),
      url('./assets/fonts/SVN-GilroyThin.woff') format('woff'),
      url('./assets/fonts/SVN-GilroyThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
