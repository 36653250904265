.input-form-profile-info-commonmanagementpage {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;
    caret-color: #3751FF;
    height: 40px;
    background: #FAFAFA;
    border: 1px solid #EFF1F1;
    border-radius: 4px;
    font-size: 14px;
}

.input-form-profile-info-commonmanagementpage:focus {
    outline: none;
    border-color: #3751FF;
    border-width: 1px;
}
.btn-export {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
    height: 13px;
}
.account-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0px 12px 0px;
    border: 1px solid #EFF1F1;
    border-radius: 8px;
    scrollbar-width: 5px;
    width: 954px;
    height: 738px;
}
.btn-create-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 58px 38px;
    width: 423px;
    height: 397px;
    background-color: #FFFFFF;
    border-radius: 8px;
    margin: auto;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #3751FF;
}
.table-pagi-text {
    width: 22px;
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #595959;
}
.table-pagi-label {
    padding: 0px 8px;
    margin: 0px 8px;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #EFF1F1;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #EFF1F1;

    width: 22px;
    height: 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #3751FF;
}
.table-pagi-hero {
    width: 22px;
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #595959;
}
