 .header-wrapper{
    background-color: #FFFFFF;
                        box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.04);
                        padding: 0px 48px 0px 48px;
                        align-items: center
 }
.nav-bar {
    max-height: 102px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    text-transform: uppercase;
}
.nav-link{
      height: 100%;
       text-align: center;
        margin-right: 14px;
         padding: 0px
}
.nav-link.active {
    background-color: #F8F9FC;
}
.nav-link.active .nav-label {
    font-weight: 700;
}
.nav-link .nav-label {
    margin-left: 8px;
    white-space: nowrap;
}
.nav-content {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding-left: 24px;
    padding-right: 24px;
}
.header-profile {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #262626;
    white-space: nowrap;
}

.dropdown-menu{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
}
.dropdown-header {
    padding: 10px 0px;
    color: #262626;
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
}
.dropdown-header-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px;
    gap: 10px;
    height: 50px;
    margin: 0;
    background-color: #FFFFFF;

    /* text */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #262626;
}
.dropdown-header-item.text-logout {
    background-color: #00000000;
    justify-content: center;
    color: #FF4D4F;
    font-weight: 400;
}

.dropdown-header-item:hover{
    background: #F8F9FC;
    color: #3751FF;
    text-decoration: none;
}
.dropdown-header-content {
    flex-direction: column;
    position: absolute;
    padding-top: 106px;
    top: 0px;
    width: 284px;
    right: 10px;
    z-index: 10;
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14); */
    border-radius: 4px;
}

@media only screen and (max-width: 576px) {
    .nav-bar {
        height: 102px;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.dropdown-menu{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0px;

    
    /* Gray / gray-1 */
    
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
}

.dropdown-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px;
    gap: 10px;
    width: 284px;
    height: 50px;
    background-color: #FFFFFF;

    /* text */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #172B4D;
}

.dropdown-item:hover{
    background-color: #E6F7FF;
}

/* .dropdown-item:focus{
    background: 'r';
} */
.user-profile{
     display: flex; flex-direction: column;
     margin-left:12px;
}

@media (min-width: 768px) and (max-width: 1200px) {
    .header-wrapper{
        max-height:60px;
        padding:0 32px;
    }
    .nav-bar {
        max-height:60px;
    }
    .nav-content {
        max-height:60px;    
        padding: 18px 14px;
    }
    .navbar-expand-lg .navbar-nav .nav-link{
        padding-right:0;
        padding-left:0;
        margin-right:0;
    }
    .user-profile{
        display:none;
    }
    .user-avt{
        height: 48px;
         width: 48px;
          background-color: red; 
          border-radius: 12px
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .nav-link .nav-label {
   font-size:12px;
}
}
