.dropdown-name-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 14px;
    padding-right: 14px;
    gap: 4px;
}
.dropdown-name-input {
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 10px 16px;
    color: #262626;
    font-weight: 400;
    font-size: 18px;
    flex-grow: 1;
    caret-color: #3751FF;
    outline: none;
}
.dropdown-name-input:focus {
    border: 1px solid #3751FF;
}
.dropdown-name-content button {
    font-weight: 700;
    font-size: 16px;
    padding: 12px 16px;
}
