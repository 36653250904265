.scrollbar-homepage {
    overflow-y: auto;
}
.scrollbar-homepage-x {
    overflow-x: scroll;
    overflow-y: unset;
}
.scrollbar-custom-homepage {
    scrollbar-color: #ff3547 #f5f5f5;
}

.scrollbar-custom-homepage::-webkit-scrollbar {
    background-color: #f5f5f5;
    border-radius: 200px;
    width: 10px;
    height: 10px;
}

.scrollbar-custom-homepage::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 200px;
    scrollbar-width: 10px;
    
}

.scrollbar-custom-homepage::-webkit-scrollbar-thumb {
    border-radius: 200px;
    background-color: #D9D9D9;
    /* background-color: #3751FF; */
    scrollbar-width: 8px;
}