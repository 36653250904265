.custom-switch-checkbox-component {
    height: 0;
    width: 0;
    visibility: hidden;
}

.custom-switch-label-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 51px;
    height: 31px;
    background: #C5C5C5;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
}

.custom-switch-label-component .custom-switch-button-component {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 27px;
    height: 27px;
    border-radius: 27px;
    transition: 0.2s;
    background-color: #3751FF;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

    position: absolute;
    width: 27px;
    height: 27px;
    left: calc(2px);
    top: calc(2px);

    /* White/100 */

    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
}

.custom-switch-checkbox-component:checked+.custom-switch-label-component .custom-switch-button-component {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.custom-switch-label-component:active .custom-switch-button-component {
    width: 27px;
}