.table-status tr, td {
    border: none !important;
}
.table .table-status-td {
    color: #262626;
    font-weight: 500;
    font-size: 14px;
    height: 32px;
    padding: 0px;
    text-align: center;
    vertical-align: middle;
}
.status-green {
    display: inline-block;
    background: #52C41A;
    width: 24px;
    height: 24px;
    border-radius: 100px;
}
.status-yellow {
    display: inline-block;
    background: #FADB14;
    width: 24px;
    height: 24px;
    border-radius: 100px;
}
.status-red {
    display: inline-block;
    background: #F5222D;
    width: 24px;
    height: 24px;
    border-radius: 100px;
}
.color-gray {
    color: #BFBFBF;
}
