.edit-arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 10000;
    padding: 24px;
    text-align: center;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}
.edit-arrow-title {
    font-weight: 600;
    font-size: 20px;
    color: #3751FF;
}
.edit-arrow-content {
    display: flex;
    width: 100%;
    border: 1px solid #EFF1F1;
    border-radius: 4px;
    padding: 16px;
    gap: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    align-items: center;
}
.edit-arrow-content-left {
    display: flex;
    gap: 8px;
    align-items: center;
}
.edit-arrow-bottom {
    display: flex;
    gap: 20px;
    justify-content: center;
}
.edit-arrow-bottom button {
    width: 121px;
    padding-top: 12px;
    padding-bottom: 12px;
}
.edit-arrow-content-right {
    display: flex;
    align-items: center;
    gap: 22px;
    padding-left: 16px;
    padding-right: 16px;
}
