.input-label {
  color: #9FA2B4;
  font-size: 14px;
  line-height: 14.7px;
  font-weight: 700;
}
.input-form-loginpage {
  width: 100%;
  height: 48px;
  display: block;
  background-color: #FCFDFE;
  color: #4B506D;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border-color: #F0F1F7;
  border-width: 1px;
  border-radius: 4px;
  padding: 14px 16px;
  margin-top: 6px;
  margin-bottom: 24px;
}
.input-form-loginpage:focus {
  width: 100%;
  height: 48px;
  display: block;
  background-color: #FCFDFE;
  color: #4B506D;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border-color: #3751FF;
  border-width: 1px;
  border-radius: 4px;
  padding: 14px 16px;
  margin-top: 6px;
  margin-bottom: 24px;
  box-shadow: none;
}
.input-info {
  position: absolute;
  right: -24px;
  top: 35px;
  white-space: nowrap;
  color: #fff;
  background: #FFA39E;
  border-radius: 8px;
  padding: 10px 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.input-info::after {
  content: "";
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2222%22%20height%3D%227%22%20viewBox%3D%220%200%2022%207%22%20fill%3D%22none%22%3E%0A%3Cpath%20d%3D%22M7.91911%201.82483C9.59234%20-0.0546942%2012.4077%20-0.0546945%2014.0809%201.82483C16.416%204.44784%2017.9895%207%2022%207H0C4.01047%207%205.58399%204.44784%207.91911%201.82483Z%22%20fill%3D%22%23FFA39E%22%2F%3E%0A%3C%2Fsvg%3E);
  background-size: 22px 7px;
  background-repeat: no-repeat;
  width: 22px;
  height: 7px;
  display: block;
  position: absolute;
  top: -7px;
  right: 22px;
}

