#popover-positioned-bottom.popover {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    border: none;
    max-width: none;
}
#popover-positioned-bottom .arrow {
    display: none !important;
}
.tooltip-date {
    display: flex;
    flex-direction: column;
    padding: 16px;
}
.tooltip-date::before {
    content: "";
    background-image: url(/src/assets/images/svg/timeline-line.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position-y: center;
    height: 24px;
    display: block;
    width: 100%;
}
.tooltip-date-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 8px;
    padding-left: 24px;
    padding-right: 24px;
    gap: 36px;
}
.tooltip-date-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    font-weight: 500;
    position: relative;
}
.tooltip-date-item::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    left: 50%;
    background-image: url(/src/assets/images/svg/timeline-circle.svg);
    background-size: 100%;
    top: -32px;
    border-radius: 50%;
    transform: translateX(-50%);
}

