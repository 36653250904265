.range-slider {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 1.5em 0;
}
.range-slider .label {
    padding: 1em;
    user-select: none;
    pointer-events: none;
}
.range-slider input {
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 1em;
    z-index: 1;
    pointer-events: none;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    outline: none;
    border-radius: 1em;
    background: none;
    border: 0;
    height: 28px;
    padding-left: 8px;
    padding-right: 8px;
}
.range-slider .slider-min::before {
    content: "";
    position: absolute;
    left: 0;
    top: -7px;
    width: 2px;
    height: 42px;
    background-color: #000;
}
.range-slider .slider-min::after {
    content: "";
    position: absolute;
    right: 0;
    top: -7px;
    width: 2px;
    height: 42px;
    background-color: #000;
}
.range-slider input:active, .range-slider input:focus {
   outline: 0;
   z-index: 2;
}
.range-slider input::-moz-focus-outer {
    border: 0;
}
.range-slider input:disabled::-moz-range-thumb {
    pointer-events: none;
}
.range-slider input:disabled::-webkit-slider-thumb {
    pointer-events: none;
}
.range-slider input::-moz-range-thumb {
    -moz-appearance: none;
    pointer-events: all;
    position: relative;
    z-index: 9;
    outline: 0;
    width: 2px;
    height: 42px;
    z-index: 10;
    background: linear-gradient(0deg, #000000, #000000), #FFFFFF;
    box-shadow: 0 .15em .5em 0 rgba(#212429, .35);
    cursor: col-resize;
    border: 0;
}
.range-slider input::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    position: relative;
    z-index: 9;
    outline: 0;
    width: 2px;
    height: 42px;
    z-index: 10;
    background: linear-gradient(0deg, #000000, #000000), #FFFFFF;
    box-shadow: 0 .15em .5em 0 rgba(#212429, .35);
    cursor: col-resize;
    border: 0;
}
.range-slider input::-moz-range-track {
    pointer-events: none;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    outline: none;
    border-radius: 1em;
    background: none;
    border: 0;
}
.range-slider input::-webkit-slider-runnable-track {
    margin: 0 -.5em;
}

.range-slider-set {
    position: relative;
    flex: 1;
    height: .5em;
    border-radius: 1em;
    background: rgba(mix(#212429, #fff), .5);
    pointer-events: none;
}
.range-slider-label {
    position: absolute;
    font-size: 11px;
    font-weight: 700;
    top: 0;
    left: 0;
    transform: translate(calc(-100% - 2px), -50%);
}
.range-slider-set [class*='span-'] {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 28px;
}
.range-slider-set .span-min {background: #52C41A;}
.range-slider-set .span-gap {background: #FADB14;}
.range-slider-set .span-max {background: #F5222D;}
.range-slider-set .handle {
    position: absolute;
    top: 100%;
    left: 50%;
    line-height: 1;
    white-space: nowrap;
    text-align: center;
    width: auto;
    cursor: pointer;
    z-index: 3;
}
.range-slider-set .handle.from-value {
    transform: translate(-50%, 0%);
    font-weight: 700;
    font-size: 14px;
}
.range-slider-set .handle.mid-value {
    transform: translate(-50%, 0%);
    font-weight: 700;
    font-size: 14px;
}
.range-slider-set .handle.to-value {
    transform: translate(-50%, 0%);
    font-weight: 700;
    font-size: 14px;
}

.input-tooltip.range-slider {
    padding-bottom: 12px;
}
.input-tooltip .range-slider-label {
    font-size: 9px;
    transform: translate(calc(-100% - 2px), -80%);
}
.input-tooltip.range-slider .range-slider-set [class*='span-'] {
    height: 14px;
}
.input-tooltip.range-slider .slider-min::before {
    height: 24px;
    top: -5px;
}
.input-tooltip.range-slider .slider-min::after {
    height: 24px;
    top: -5px;
}
.input-tooltip.range-slider input {
    height: 14px;
}
.input-tooltip.range-slider .handle {
    top: 1.5px;
    font-size: 10px;
}
.input-tooltip.range-slider input::-webkit-slider-thumb {
    height: 24px;
}

