.station-table-homepage {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #EFF1F1;
    border-radius: 8px;
    min-height: 0;
}

.item-title-homepage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 18px 8px 12px;
    width: 100%;
    height: 50px;
    background: linear-gradient(90deg, #EEF3FF -1.13%, rgba(238, 243, 255, 0) 105.86%);
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    /* text */
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #262626;
}
.title-num {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #3751FF;
}
.text-blue {
    color: #3751FF;
    font-weight: 500;
}
.label-green {
    width: 32px;
    height: 8px;
    background: #52C41A;
    border-radius: 200px;
}
.label-red {
    width: 32px;
    height: 8px;
    background: #F5222D;
    border-radius: 200px;
}
.label-yellow {
    width: 32px;
    height: 8px;
    background: #FADB14;
    border-radius: 200px;
}
.item-homepage-content {
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 10px;
    padding-right: 5px;
    /* width: 100%; */
    width: calc(100% - 5px);
    overflow: auto;
    height: calc(100% - 66px);
}
.item-content-border-homepage {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
    width: 100%;
    height: 38px;
    border: 1px solid #EFF1F1;
    border-radius: 2000px;
    margin-bottom: 12px;

    /* text */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
}
