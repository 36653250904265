.login-page-loginpage {
    position: relative;
    max-width: 1920px;
    height: 100%;
    margin: 0px;
    padding: 0px;
    align-content: center;
    justify-items: center;
}
.col-banner-loginpage {
    max-width: 857px;
    width: 720px;
    height: 100%;
    margin: 0px;
    padding: 0px;
    display: block;
}
.col-login-form-loginpage {
    max-width: 857px;
    width: 720px;
    height: auto;
    margin: 0px;
    padding: 0px;
    display: block;
    padding: 0px;
    align-self: center;
}
.login-form-loginpage {
    max-width: 857px;
    width: 720px;
    min-height: auto;
    width: 100%;
    padding-left: 73px;
    padding-right: 73px;
    /* padding-top: 182px; */
    display: block;
}
.logo-loginpage{
    display: block;
    height: 100%;
    max-width: 282px;
}
.submit-button-loginpage{
    display: block;
    width: 100%;
    margin-top: 0px;
    background-color: #3751FF;
    border-width: 0px;
    border-radius: 4px;
    box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
    padding: 17px 28px;
    text-align: center;
    opacity: 1;
}
.submit-button-loginpage span {
    font-style: normal;
    font-size: 19px;
    height: 24px;
    line-height: 24px;
    font-weight: 600;
    color: #FFFFFF;
    letter-spacing: 0.23814px;
    flex: none;
}
.submit-button-loginpage:hover{
    opacity: 0.8;
}
.hide-password-button-loginpage{
    border: none;
    background-color: inherit;
}
.hide-password-button-loginpage:hover{
    opacity: 0.6;
}
.error-label-loginpage{
    background-color :#FFF1F0 ; 
    width:100% ; 
    align-items:center;
    gap:8px;
    padding:12px 16px 12px 16px;
    margin: 32px 0px 32px 0px;
    border: 0.8px solid #FF4D4F;
    border-radius: 4px;
}

.login-title {
    display: flex;
    justify-content: center;
    margin-top: 32px;
}
.login-title span {
    font-style: normal;
    font-size: 24px;
    height: 25px;
    line-height: 25px;
    font-weight: 700;
    color: #252733;
    flex: none;
}
.login-des {
    display: flex;
    justify-content: center;
    margin-top: 14px;
    margin-bottom: 32px;
}
.login-des span {
    font-style: normal;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    font-weight: 400;
    color: #9FA2B4;
    flex: none;
}

@media only screen and (max-width: 1300px) {
    .col-banner-loginpage {
        display: none;
        width: 0%;
    }
    .col-login-form-loginpage {
        max-width: 100%;
        display: block;
        width: 100%;
        margin-right: 10%;
        margin-left: 10%;
    }
    .login-form-loginpage {
        min-height: auto;
        width: 100%;
        padding: 32px;
        display: block;
    }
}
/* mobile */
@media only screen and (max-width: 720px) {
    .col-login-form-loginpage {
        width: 100%;
        max-width: 720px;
        display: block;
        padding: 0px;
        margin: 0px;
    }

    .login-form-loginpage {
        min-height: auto;
        width: 100%;
        padding: 32px;
        display: block;
    }
    .submit-butto-loginpage{
        margin-top: 0px;
        background-color: #3751FF;
        border-width: 0px;
        border-radius: 4px;
        box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
        padding: 8px 28px;
    }
}
