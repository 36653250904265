.dropdown-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-weight: 400;
  background: #FFFFFF !important;
  border: 1px solid #D9D9D9 !important;
  border-radius: 2px;
  font-size: 14px;
  line-height: 22px;
  color: #262626 !important;
  padding: 4px 12px;
  min-width: 140px;
}
.dropdown-label::after {
  width: 14px;
  height: 14px;
  background-image: url(/src/assets/images/svg/arrow-down-select.svg);
  border: none;
}
.dropdown-label:hover {
  background-color: unset;
  color: unset;
  border-color: unset;
}
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary:focus {
  box-shadow: none !important;
}
.dropdown-menu {
  min-width: 140px;
  padding: 8px !important;
}
.dropdown-menu .dropdown-item {
  padding: 5px 12px;
  border-bottom: 1px solid #F0F0F0;
  display: block;
  width: 100%;
  /* clear: both; */
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  height: auto;
  line-height: normal;
  letter-spacing: normal;
}
.dropdown-menu .dropdown-item:hover {
  background-color: unset;
  color: unset;
}
.dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
}
