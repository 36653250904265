.config-time-table-controlpanelpage{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 308px;
    height: 256px;
    border: 1px solid #EFF1F1;
    border-radius: 4px;
}

.config-light-direction-table-controlpanelpage{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 308px;
    height: 300px;
    background: #FFFFFF;
    border: 1px solid #EFF1F1;
    border-radius: 4px;
}
.custom-file-input-controlpanelpage {
    border-radius: 4px;
}
.custom-file-input-controlpanelpage input.upload {
    display: inline-block;
}

.custom-file-input-controlpanelpage input.preview {
    display: inline-block;
}