.formlist-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 352px;
  border: 1px solid #EFF1F1;
  border-radius: 8px;
  height: 445px;
}
.formlist-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 133%;
  text-align: center;
  color: #172B4D;
}
