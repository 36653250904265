.timer-container {
    display: flex;
    gap: 8px;
    align-items: center;
    position: relative;
}
.timer-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    color: #000000;
}
.timer-content {
    width: 120px;
    height: 32px;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.timer-content-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
    width: 96px;
}
.timer-keeper {
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 1;
}
