#container {
    position: relative;
    width: 600px;
    height: 600px;
    background-color: gray;
    margin: auto;
}

/* make content of each edit box fit 100% */
.testimage {
    width: 100%;
    height: 100%;
}

.edit-box {
    position: absolute;
}

.box {
    position: static;
    width: 100%;
    height: 100%;
    background-color: blue;
}

.rotatable-box {
    position: static;
    width: 100%;
    height: 100%;
}

.rotate-control,
.resize-control {
    /* position: absolute; */
    /* bottom: 0px; */
    /* margin-bottom: -20px; */
    /* width: 14px;
    height: 14px;
    background-color: #3751FF;
    border: 1px solid #FFFFFF;
    border-radius: 28px; */
    /* margin-left: -5px; */
}

.rotate-control {
    /* left: 50%; */
}

.resize-control {
    /* bottom: 0px;
    right: 0px; */
}

.rotate-control:hover,
.resize-control:hover {
    cursor: pointer;
}

.rotate-control:before {
    content:  attr(data-attr)'\00B0';
}


.content {
    position: absolute;
}

.resizeControls {
    width: auto;
    height: auto;
}

.editContainer {
    position: absolute;
    background-color: lightgrey;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    cursor: standard;
    /* uncomment below to see rotation */
    /*transform: rotate(45deg);*/
}


.resizeHandle {
    width: 10px;
    height: 10px;
    position: absolute;
    background-color: darkgreen;
    opacity: 1;
    margin: -8px;
}

.corner {
    background-color: transparent;
    border: 3px solid darkgreen;
}

.resizeHandle.topLeft {
    top: 0px;
    left: 0px;
    cursor: nw-resize;
    border-radius: 0;
    border-right: none;
    border-bottom: none;
}

.resizeHandle.top {
    top: 0px;
    left: 50%;
    margin-left: -5px;
    cursor: n-resize;
}

.resizeHandle.topRight {
    top: 0px;
    right: 0px;
    cursor: ne-resize;
    border-left: none;
    border-bottom: none;
}

.resizeHandle.right {
    top: 50%;
    right: 0px;
    margin-top: -5px;
    cursor: e-resize;
}

.resizeHandle.bottomRight {
    bottom: 0px;
    right: 0px;
    cursor: se-resize;
    border-left: none;
    border-top: none;
}

.resizeHandle.bottom {
    bottom: 0px;
    right: 50%;
    margin-right: -5px;
    cursor: s-resize;
}

.resizeHandle.bottomLeft {
    bottom: 0px;
    left: 0px;
    cursor: sw-resize;
    border-right: none;
    border-top: none;
}

.resizeHandle.left {
    top: 50%;
    left: 0px;
    margin-top: -5px;
    cursor: w-resize;
}
.item-text {
    font-family: SVN-Gilroy;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #3751FF;
    border: none;
    outline: none;
    border-radius: 200px;
    cursor: pointer;
    background-color: white;
    padding: 8px 20px;
    /* min-width: 100px; */
    min-width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
