.button-base {
    background: #3751FF;
    border-radius: 4px;
    padding: 7px 32px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    border: none;
}
.button-base-disable:disabled {
    background-color: #76A9FA;
}
