.drawer-option-managementcommonpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0px;
    gap: 14px;
    width: 340px;
    height: 986px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}

.drawer-option-managementcommonpage-label {
    font-weight: 700;
    font-size: 20px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
}

.content-table-managementcommonpage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 16px;
    gap: 24px;
    width: 986px;
    height: 986px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}
