.row-table-report {
    display: flex;
    align-items: center;
    padding: 12px;
    height: 55px;
    gap: 24px;
    background-color: #fff;
}
.row-table-report:nth-child(odd) {
    background-color: #F8F9FC;
}

.item-title-table-report-commonmanagementpage {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #3751FF;
}

.item-content-table-report-commonmanagementpage {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
}