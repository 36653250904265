.drawer-option-strategypage{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 24px;
    width: 340px;
    height: 814px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}
.content-table-strategypage{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 16px;
    width: 986px;
    height: 814px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    gap: 20px;
}