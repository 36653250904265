.item-option-profile-info-systemsettingpage{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    width: 100%;
    border-width: 0px;
    border-radius: 0px;
    margin-bottom: 14px;
    caret-color: #00000000;
    width: 100%;
    text-decoration: none;
    background: #FFFFFF;
    color: #262626;
    font-weight: 500;
    height: auto;
    margin: 0;
}
.item-option-profile-info-systemsettingpage.active {
    background: #F8F9FC;
    color: #3751FF;
}
.item-option-profile-info-systemsettingpage:hover {
    color: currentColor;
}
.item-option-profile-info-systemsettingpage.active:hover {
    color: #3751FF;
}
.manual-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
}


