.item-station-strategypage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    gap: 7px;
    width: 290px;
    height: 62px;
    background: #F5F7F9;
    border-radius: 4px;
    cursor: pointer;
}
.item-title-station-strategypage{
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    color: #172B4D;
}

.heading-profile-info-strategypage {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 133%;
  text-align: center;
  color: #3751FF;

}

.subtitle-station-name-strategypage{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
}

.title-tab-strategy-option-selected-strategypage{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 133%;
  text-align: center;
  color: #FFFFFF;
}

.title-tab-strategy-option-unselected-strategypage {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 133%;
  text-align: center;
  color: #3751FF;
}
.station-navs.schedule-navs {
  background-color: #fff;
}
.station-navs.schedule-navs .nav-item {
  border: none;
  padding: 10px 24px;
  font-weight: 500;
  font-size: 16px;
  color: #3751FF;
  border-radius: 0;
}
.station-navs.schedule-navs .nav-item.active {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #fff;
  background-color: #3751FF;
}
.station-navs.schedule-navs + .tab-content {
  padding-top: 20px;
  border-top: 1px solid #F0F0F0;
  width: 100%;
  height: calc(100% - 152px);
}
.station-navs.schedule-navs + .tab-content > .tab-pane {
  height: calc(100% - 40px);
}
.button-base.schedule-async {
  position: absolute;
  right: 0;
  top: 2px;
  padding: 10px 20px;
}
.button-base.schedule-async:disabled {
  background-color: #76A9FA;
}

.chart-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.chart-content-main {
  flex-grow: 1;
}
.chart-content-top {
  flex-grow: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.chart-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.chart-footer {
  border: 1px solid #F0F0F0;
  border-radius: 8px;
}
.chart-footer-title {
  background: #F0F0F0;
  padding: 6px 24px;
  font-weight: 700;
  font-size: 14px;
  color: #172B4D;
}
.chart-footer-content {
  background: #F0F0F0;
  display: flex;
  column-gap: 2px;
}
.chart-footer-item {
  width: 50%;
  padding: 16px 14px;
  background: #FAFAFA;
  font-weight: 400;
  font-size: 12px;
  color: #262626;
  flex-grow: 1;
  gap: 6px;
  display: flex;
  flex-direction: column;
  max-height: 74px;
}
.strategy-time-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  /* height: 348px; */
  height: 402px;
  gap: 20px;
  padding: 12px;
}
.footer-strategy-time {
  border-top: 1px solid #F0F0F0;
  padding: 6px 14px;
  text-align: right;
  width: 100%;
}
.footer-strategy-time .button-base {
  font-weight: 700;
  padding: 10px 20px;
}

.strategy-date-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 400px;
  gap: 20px;
  padding: 20px 12px;
  background-color: #FAFAFA;
}
.timeline {
  position: relative;
  width: 100%;
  padding: 12px 12px 12px 110px;
}
.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: #BFBFBF;
  top: 0;
  bottom: 0;
  left: 66px;
}
.timeline-item {
  padding: 14px;
  width: 100%;
  background-color: #fff;
  filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.1));
  position: relative;
  z-index: 1;
  margin-bottom: 32px;
  min-height: 60px;
}
.timeline-item::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 4px;
  width: 0;
  z-index: 1;
  left: -13px;
  border: medium solid white;
  border-width: 25px 20px 25px 0;
  border-color: transparent white transparent transparent;
}
.timeline-item::after {
  content: '';
  position: absolute;
  width: 32px;
  height: 32px;
  left: -60px;
  background-image: url(/src/assets/images/svg/timeline-circle.svg);
  top: 13px;
  border-radius: 50%;
}
.timeline-item-top {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #172B4D;
}
.timeline-item-content {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-top: 16px;
}
.timeline-item-left {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.timeline-item-right {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-right: 12px;
}
.timeline-left-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
