.button-small {
    padding: 4px 12px;
    border-radius: 200px;
}
.traffic-title {
    font-weight: 700;
    font-size: 18px;
    color: #3751FF;
    text-align: center;
    margin-bottom: 10px;
}
.traffic-sign {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 12px;
    margin-bottom: 16px;
}
.traffic-text {
    font-size: 14px;
    color: #262626;
}
.traffic-green {
    background: #389E0D;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.traffic-red {
    background: #F5222D;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.traffic-gray {
    background: #D9D9D9;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
