input[type="file"] {
    display: none;
}

.custom-file-input-commonmanagementpage input.upload {
    display: inline-block;
}

.upload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    /* padding: 12px 16px; */
    /* gap: 10px; */
    background-color: #3751FF;
    border-radius: 4px;
    border-width: 0px;
    color: white;
    cursor: pointer;
    height: 42px;
    width: 93px;

    /* text */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #FFFFFF;
}