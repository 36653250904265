.bottom-buttom-component{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #3751FF;
    border-radius: 4px;
    cursor: pointer;
    flex-shrink: 0;
}

.bottom-buttom-component:active {
    background-color: rgba(55, 81, 255, 0.8);
}