.item-station-managementdevicepage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    gap: 7px;
    width: 290px;
    height: 62px;
    background: #F5F7F9;
    border-radius: 4px;
    cursor: pointer;
}
.item-title-station-managementdevicepage{
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    color: #172B4D;
}

.heading-profile-info-managementdevicepage {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 133%;
  text-align: center;
  color: #3751FF;

}

.subtitle-station-name-managementdevicepage{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
}

.title-tab-strategy-option-selected-managementdevicepage{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 133%;
  text-align: center;
  color: #FFFFFF;
}

.title-tab-strategy-option-unselected-managementdevicepage {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 133%;
  text-align: center;
  color: #3751FF;
}