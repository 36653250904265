.item-menu-option-commonmanagementpage{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 13px;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    cursor: pointer;
}
.item-menu-option-commonmanagementpage.active {
    background-color: #F8F9FC;
}
.item-menu-option-commonmanagementpage.active .item-menu-option-content-commonmanagementpage {
    color: #3751FF;
}
.item-menu-option-content-commonmanagementpage{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
}