.add-station-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #3751FF;
}
.add-station-loading {
  font-weight: 400;
  font-size: 14px;
  line-height: 133%;
  color: #595959;
  margin-top: 10px;
}
.add-station-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  height: 42px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #3751FF;
  width: 262px;
  cursor: pointer;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #3751FF;
}
.add-station-check {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  height: 42px;
  background: #3751FF;
  border-radius: 4px;
  width: 262px;
  cursor: pointer;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
}

