.select-form {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance: none;
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  outline: none;
  padding: 7px 30px 7px 16px;
  color: #262626;
  font-weight: 500;
  font-size: 16px;
  background-image: url(../../../assets/images/svg/arrow-down-select.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}
