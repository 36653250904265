.slider-wrapper{
            display: flex;
          flex-direction: column;
        }
.image-slide{
    width:100%;
}
@media(min-width: 768px) and (max-width:1200px) {
        .slider-wrapper{
            max-width:632px;
        }
}

.title-item_slide-show-homepage {
    /* height: 27px; */
    max-width: 441px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    /* line-height: 133%; */
    text-align: center;
    text-transform: uppercase;
    color: #3751FF;
}

.content-item_slide-show-homepage {
    max-width: 441px;
    /* height: 57px; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #000000;
}

.carousel-indicators-homepage{
    position: absolute;
    margin: auto;
    bottom: 14px;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: auto;
    width: 100%;
}