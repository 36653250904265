.item-heading-managementdevicepage {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 133%;
    text-align: center;
    color: #3751FF;
}

.input-form-profile-info-managementdevicepage {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    caret-color: #3751FF;
    height: 44px;
    background: rgba(55, 81, 255, 0.06);
    border: none;
    border-radius: 4px;
    gap: 10px;
    width: 804px;

}

.input-form-profile-info-managementdevicepage:focus {
    outline: none;
    border-color: #3751FF;
    border-width: 1px;
}

.input-form-profile-info-managementdevicepage::-webkit-input-placeholder {
    /* Edge */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    text-align: start;
    color: rgba(55, 81, 255, 0.5);
}

.input-form-profile-info-managementdevicepage:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    text-align: start;
    color: rgba(55, 81, 255, 0.5);
}

.input-form-profile-info-managementdevicepage::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    text-align: start;
    color: rgba(55, 81, 255, 0.5);
}

.item-operation-history-managementdevicepage {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    width: 954px;
    height: 280px;
    background: #FAFAFA;
    border: 1px solid #D6DFF6;
    border-radius: 8px;
}

.item-operation-history-chat-managementdevicepage {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 16px;
    gap: 10px;
    width: 934px;
    height: 52px;
    background: #FFFFFF;
    border-radius: 200px;
    caret-color: #3751FF;
    border: 1px solid #D9D9D9;
}
.item-operation-history-chat-managementdevicepage:focus-within {
    border: 1px solid #3751FF;
}

.item-operation-history-content-managementdevicepage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 934px;
    height: 198px;
}

.input-form-operation-history-chat-managementdevicepage {
    width: 868px;
    height: 19px;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    color: #595959;
    border: none;
}

.input-form-operation-history-chat-managementdevicepage:focus {
    /* border: none; */
    outline: none;
}

.item-form-title-table-managementdevicepage {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap: 20px;
    height: 43px;
    width: 100%;
    opacity: 0.8;
    border-bottom: 1px solid #EFF1F1;
}

.item-title-table-managementdevicepage {
    height: 19px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #3751FF;
}

.item-form-content-table-managementdevicepage {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    height: 55px;
    background: #FFFFFF;
    margin-bottom: 10px;
}

.item-content-table-managementdevicepage {
    height: 19px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
}

.input-content-table-managementdevicepage {
    box-sizing: border-box;
    height: 30px;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
}
.input-content-table-managementdevicepage:focus{
    outline: none;
}