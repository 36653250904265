.custom-calender-button-component{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #D6DFF6;
    border-radius: 4px;
}
.custom-calender-component{
    align-items: flex-start;
    padding: 10px;
    gap: 15px;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 10px;

    display: flex;
    flex-direction: column;
}
.custom-calender-range .rdrDefinedRangesWrapper {
    display: none;
}
.custom-calender-range .rdrCalendarWrapper .rdrDateDisplayWrapper {
    display: none;
}
.custom-calender-range .rdrCalendarWrapper .rdrMonthAndYearWrapper {
    padding-top: 0;
    height: 38px;
}
.custom-calender-range .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrPprevButton i {
    display: none;
}
.custom-calender-range .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextButton i {
    display: none;
}
.custom-calender-range .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrPprevButton {
    background-image: url(/src/assets/images/svg/prev-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
}
.custom-calender-range .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextButton {
    background-image: url(/src/assets/images/svg/next-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
}
.rdrMonthAndYearPickers .rdrMonthPicker, .rdrMonthAndYearPickers .rdrYearPicker {
    border: 0.6px solid #CDCFD1;
    border-radius: 5px;
}
.rdrMonthAndYearPickers .rdrMonthPicker select, .rdrMonthAndYearPickers .rdrYearPicker select {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    padding-top: 4px;
    padding-bottom: 4px;
}
.rdrMonthsVertical .rdrMonth {
    padding: 0;
}
.rdrMonthsVertical .rdrMonth .rdrWeekDays {
    border-bottom: 2px solid #EBECED;
    margin-top: 16px;
}
.rdrMonthsVertical .rdrMonth .rdrWeekDays .rdrWeekDay {
    font-size: 14px;
    font-weight: 500;
    color: #687076;
    text-transform: uppercase;
    line-height: 1.25rem;
}
.custom-calender-range .rdrDays {
    margin-top: 10px;
}
.custom-calender-range .rdrDayNumber span {
    color: #687076;
    font-size: 12px;
    font-weight: 500;
}
.custom-calender-range .rdrDayNumber {
    font-weight: 500;
}
.custom-calender-range .rdrStartEdge, .custom-calender-range .rdrEndEdge {
    color: #EBECED !important;
}
.custom-calender-range .rdrInRange {
    color: #EBECED !important;
}
.custom-calender-range .rdrInRange + .rdrDayNumber span {
    color: #687076 !important;
}
.rdrStartEdge + .rdrDayNumber span, .rdrEndEdge + .rdrDayNumber span {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3751FF;
    border-radius: 50%;
}
.custom-calender-range .rdrStartEdge {
    left: 11px !important;
}
.custom-calender-range .rdrEndEdge {
    right: 11px !important;
}
.custom-calender-range .rdrDay.rdrDayToday .rdrDayNumber span::after {
    display: none;
    background-color: #3751FF;
}
