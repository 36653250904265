.tab-bar-trafficlight-strategypage {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 352px;
    height: 581px;
    background: #FFFFFF;
    border: 1px solid #EFF1F1;
    border-radius: 8px;
}

.item-form-title-table-trafficlight-strategypage {
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    width: 556px;
    height: 44px;
    border-bottom: 1px solid #EFF1F1;
}

.item-title-table-trafficlight-strategypage {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #3751FF;
}

.item-form-content-table-trafficlight-strategypage {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 20px;
    width: 556px;
    height: 54px;
    /*background: #c53f3f;*/
}

.item-content-table-trafficlight-strategypage {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
}

.input-form-strategypage {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;
    caret-color: #3751FF;

    height: 40px;
    background: #FAFAFA;
    border: 1px solid #EFF1F1;
    border-radius: 4px;

}

.input-form-strategypage:focus {
    outline: none;
    border-color: #3751FF;
    border-width: 1px;
}

.input-form-phase-strategypage {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    caret-color: #3751FF;
    background-color: #00000000;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    border-right-style: none;

    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #3751FF;

    /* text */
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #172B4D;

}

.input-title-phase {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #172B4D;
    /*width: 60px;*/
}

.input-form-phase-strategypage:focus {
    outline: none;
}