.management-action {
    width: 100%;
    display: flex;
    gap: 35px;
    justify-content: space-between;
    align-items: center;
}

.input-placerhold-station-search-commonmanagementpage {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    caret-color: #3751FF;
    height: 44px;
    background: #fff;
    gap: 10px;
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
}

.input-placerhold-station-search-commonmanagementpage:focus {
    outline: none;
    border-color: #3751FF;
    border-width: 1px;
}

.bottom-sheet-add-account-commonmanagementpage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 58px 38px; */
    /* gap: 44px; */
    width: 100%;
    /* height: 720px; */
    background-color: #FFFFFF;
    border-radius: 8px;
    margin: auto;
    gap: 20px;
}

.input-form-commonmanagementpage {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;
    caret-color: #3751FF;
    height: 48px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #D9D9D9;
}

.input-form-commonmanagementpage:focus {
    outline: none;
    border-color: #3751FF;
    border-width: 1px;
}

.title-overview-info-commonmanagementpage {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 142%;
    display: block;
    align-items: center;
    color: #172B4D;
    width: 142px;
}

.selector-form-commonmanagementpage {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 8px;
    width: 467px;
    background: rgba(250, 250, 250, 0.6);
    border: 1px solid #D6DFF6;
    border-radius: 4px;
}
.station-cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;
    height: 44px;
    background: #fff;
    width: 136.5px;
    cursor: pointer;
    flex-shrink: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #3751FF;
    border: 1px solid #3751FF;
    border-radius: 4px;
}
.station-apply {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;
    height: 44px;
    background: #3751FF;
    width: 136.5px;
    cursor: pointer;
    flex-shrink: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    border-radius: 4px;
}
.modal-sheet-bottom {
    display: flex;
    width: 100%;
    gap: 30px;
    justify-content: space-between;
}
.modal-sheet-bottom > button {
    font-size: 16px;
    font-weight: 700;
    height: 42px;
    flex-grow: 1;
}
.modal-sheet-bottom > button:disabled {
    opacity: 0.8;
}
.section-assign {
    display: flex;
    align-items: center;
    gap: 20px;   
    width: 100%;
}
.section-assign-label {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #262626;
    width: 142px;
    display: block;
    text-align: right;
}
.section-assign .button-apply {
    width: auto;
    font-size: 16px;
    font-weight: 700;
    height: 42px;
    padding: 0 16px;
}
.select-device-wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.select-device {
    background-color: #fff;
    min-width: 237px;
    padding: 14px;
}
.select-device-content {
    max-height: 150px;
    overflow-y: auto;
    margin-bottom: 20px;
}
.select-device-item {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
}
.select-device-item:last-child {
    margin-bottom: 0;
}
.select-device-item > input {
    width: 16px;
    height: 16px;
    border: 1px solid #D1D5DB;
    border-radius: 2px;
    background: #F9FAFB;
}
.select-device-item-text {
    font-family: 'SVN-Gilroy';
    font-weight: 500;
    font-size: 14px;
}
.select-device-bottom {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
}
.select-device-bottom .button-cancel {
    height: 42px;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid #3751FF;
}
