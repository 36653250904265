.heading-overview-info-systemsettingpage {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 133%;
    text-align: center;
    color: #3751FF;

}

.title-overview-info-systemsettingpage {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 142%;
    display: flex;
    align-items: center;
    color: #262626;
}

.content-overview-info-systemsettingpage {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 142%;
    display: flex;
    align-items: center;
    color: #687076;
}

.input-form-overview-info-systemsettingpage {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;
    caret-color: #3751FF;

    height: 40px;
    background: #FAFAFA;
    border: 1px solid #EFF1F1;
    border-radius: 4px;

}

.input-form-overview-info-systemsettingpage:focus {
    outline: none;
    border-color: #3751FF;
    border-width: 1px;
}

.password-form-overview-info-systemsettingpage {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 9px 10px;
    caret-color: #3751FF;

    height: 40px;
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;

}

.password-form-overview-info-systemsettingpage:focus {
    outline: none;
    border-color: #3751FF;
    border-width: 1px;
}

.selector-form-systemsettingpage {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 8px; 
    width: 467px;
    background: rgba(250, 250, 250, 0.6);
    border: 1px solid #D6DFF6;
    border-radius: 4px;
}