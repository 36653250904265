input[type="file"]
{
  display: none;
}
.custom-file-input-systemsettingpage input.upload 
{
    display: inline-block;
}

.custom-file-input-systemsettingpage input.preview 
{
    display: inline-block;
}
.upload{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;
    background-color: #3751FF;
    border-radius: 4px;
    border-width: 0px;
    color: white;
    cursor: pointer;
}

.preview{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 200px;
    height: 200px;
    background: rgba(250, 250, 250, 0.6);
    border: 1px solid #D6DFF6;
    border-radius: 4px;
}
.preview > img {
    border-radius: 4px;
}
