.item-option-profile-info-profilepage{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    background: #FFFFFF;
    border-width: 0px;
    border-radius: 0px;
    margin-bottom: 14px;
    caret-color: #00000000;
    width: 100%;
    text-decoration: none;
    color: #262626;
    font-weight: 500;
    height: auto;
    margin: 0;
}
.item-option-profile-info-profilepage.active {
    background: #F8F9FC;
    color: #3751FF;
}
.item-option-profile-info-profilepage:hover {
    color: currentColor;
}
.item-option-profile-info-profilepage.active:hover {
    color: #3751FF;
}
