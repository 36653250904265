.item-traffic-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    padding: 0px;
    gap: 16px;
    /* width: 290px; */
    margin-bottom: 16px;
}
.item-traffic-container:last-child {
    margin-bottom: 0;
}
.traffic-light-group {
    background: #172B4D;
    border-radius: 200px;
    padding: 5px 7px;
    display: flex;
    flex-direction: row;
    gap: 4px;
}
.countdown-content {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    width: 50%;
    height:73px;
    border-right: 1px solid #F0F0F0;
    padding-right: 27px;
    padding-left: 8px;
}
.countdown-label {
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    color: #172B4D;
}
.countdown-input {
    display: flex;
}
.countdown-input input {
    width: 100%;
    color:#3751FF;
    font-size:32px;
    line-height:32px;
}
.signature-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 140px;
    height: 73px;
}
.signature-label {
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    color: #172B4D;
}
.item-traffic-header {
    display: flex;
    width: 100%;
    gap: 12px;
    align-items: center;
    border-bottom: 2px solid #EFF1F1;
    justify-content: space-between;
}
.item-traffic-header-label {
    font-weight: 700;
    font-size: 16px;
    color: #172B4D;
    padding: 8px;
    padding-right: 0;
    margin-bottom: -2px;
    border-bottom: 2px solid #3751FF;
}
.item-traffic-header-name {
    font-weight: 700;
    font-size: 16px;
    color: #3751FF;
}
.item-traffic-header-input {
    width: 100%;
    color: #111928;
    font-size: 14px;
    border: none;
    outline: none;
    border-bottom: 2px solid #3751FF;
    caret-color: #3F83F8;
    padding-bottom: 4px;
    padding-top: 4px;
}
