.light-container-yellow {
    width: 42px;
    height: 42px;
    border-radius: 84px;
    background-color: #876800;
    box-shadow: none;
    opacity: 0.8;
}
.light-container-yellow.active {
    background-Color: #FADB14;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.24);
    opacity: 1;
}

.light-item-yellow {
    display: none;
    width: 42px;
    height: 42px;
    border-radius: 84px;
    filter: blur(4.5px);
    background-color: #FADB14;
}
.light-item-yellow.active {
    display: block;
}

.light-container-red {
    width: 42px;
    height: 42px;
    border-radius: 84px;
    background-color: #820014;
    box-shadow: none;
    opacity: 0.8;
}
.light-container-red.active {
    background-Color: #F5222D;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.24);
    opacity: 1;
}

.light-item-red {
    display: none;
    width: 42px;
    height: 42px;
    border-radius: 84px;
    filter: blur(4.5px);
    background-color: #FF3B45;
}
.light-item-red.active {
    display: block;
}

.light-container-green {
    width: 42px;
    height: 42px;
    border-radius: 84px;
    background-color: #135200;
    box-shadow: none;
    opacity: 0.8;
}
.light-container-green.active {
    background-Color: #52C41A;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.24);
    opacity: 1;
}

.light-item-green {
    display: none;
    width: 42px;
    height: 42px;
    border-radius: 84px;
    filter: blur(4.5px);
    background-color: #52C41A;
}
.light-item-green.active {
    display: block;
}

