.bottom-sheet-forgot-account-commonmanagementpage{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 20px;
  /* gap: 44px; */
  width: auto;
  background-color: #FFFFFF;
  border-radius: 8px;
}
.forgot-account-text {
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6B7280;
  margin-bottom: 4px;
}
.forgot-account-text span {
  font-weight: 700;
}
.forget-password-button-loginpage{
  border: none;
  background-color: inherit;
  padding: 14px 28px;
  font-size: 16px;
  display: block;
  margin: auto;
  margin-top: 20px;
}
.forget-password-button-loginpage span {
  font-style: normal;
  font-size: 17px;
  height: 24px;
  line-height: 24px;
  font-weight: 400;
  color: #9FA2B4;
  letter-spacing: 0.357209px;
  flex: none;
}
.forget-password-button-loginpage:hover span {
  color: #3751FF;
  text-decoration: underline;
}
.title-overview-info-commonmanagementpage {
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: right;
  color: #172B4D;
  width: 142px;
  display: block;
}
.forgot-title {
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
  margin-top: 16px;
  margin-bottom: 4px;
}
.forgot-btn {
  padding: 10px 26px;
  background: #1C64F2;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}
.modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.h-line {
  height: 1px;
  background-color:#E5E7EB;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 20px;
}
