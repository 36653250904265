.content-wrapper{
     width: 100%; 
     display: flex; 
     flex-direction: row; 
     justify-content: center; 
     margin-top: 30px;
    margin-bottom: 30px
}
.new-activities-list-homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 16px;
    width:  434px;
    max-height: 814px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: 1px solid #F0F0F0;
    margin-left:20px;
    /* margin-top: 30px;
    margin-bottom: 74px; */
    
}


.statistical-table-homepage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 20px;

    width: 891px;
    height: 334px;

    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
     margin-top: 20px; display: flex;
      flex-direction: column
}
.item-notify-homepage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px;
    gap: 8px;
    width: auto;
    background: #FAFAFA;
    border-radius: 8px;
    margin-bottom: 14px;
    margin-right: 8px;
    border: 1px solid #F0F0F0;
}
.h-line-16 {
    height: 1px;
    background-color: #E5E7EB;
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
}
.statistical-content {
    display: grid;
    width: 100%;
    height: 100%;
    min-height: 0;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
}
.new-activities-list-homepage-tablet{
    display:none;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 16px;
    width:  434px;
    max-height: 326px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: 1px solid #F0F0F0;
    margin-left:24px;
    /* margin-top: 30px;
    margin-bottom: 74px; */
}
.activity-title{
    height: 27px;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 133%;
    text-align: start;
    color: #172B4D
}
/* responsive tablet */
@media(min-width: 768px) and (max-width:1200px) {
    .new-activities-list-homepage {
        width:  unset;
    }
    .statistical-table-homepage {
        width:unset;
    }
    .item-notify-homepage {
        width:100%
    }
    .new-activities-list-homepage{
        display:none;
    }
    .new-activities-list-homepage-tablet{
        display: flex;
        max-width:303px;
    }
    .activity-title{
        font-size:16px;
        line-height:1.7;
    }
    .item-notify-homepage{
        max-width:270px;
    }
}