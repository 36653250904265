.activity-content.tooltip-form-content {
  height: auto;
  border: none;
  gap: 4px;
}
.activity-header.tooltip-form-header {
  background-color: #fff;
  border: none;
  padding: 8px 12px 0px 12px;
}
.popover {
  z-index: 1000000;
}
.popover.popover-schedule {
  min-width: 480px;
  border-radius: 0;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
}
