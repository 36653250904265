.input-search {
    display: flex;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    background: #F9FAFB;
    padding: 8px 20px 8px 48px;
    width: 100%;
    background-image: url(/src/assets/images/svg/search-icon.svg);
    background-repeat: no-repeat;
    background-position: 18px;
    transition: all 0.15s;
    align-items: center;
}
.input-search:focus-within {
    border: 1px solid #1C64F2;
    caret-color: #1C64F2;
}
.input-search input {
    font-size: 14px;
    color: #6B7280;
    outline: none;
    width: 100%;
    border: none;
    background: transparent;
}
