.controlpannel-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 20px;
}
.controlpannel-hero {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 133%;
    text-align: left;
    color: #172B4D;
    margin-left: 8px;
}
.drawer-option-controlpanelpage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 16px;
    width: 340px;
    /* min-width: 340px; */
    height: 814px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}
.drawer-option-controlpanelpage.hide::after {
    content: "HOVER HERE";
    color: #3751FF;
    font-weight: 500;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: -35%;
    white-space: nowrap;
    transform: rotate(-90deg);
}

.content-table-controlpanelpage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 30px 16px; */
    width: 986px;
    height: 814px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}
.option-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
}
.radio-group {
    display: flex;
    align-items: center;
    gap: 10px;
}
.radio-group-icon {
    border: 1px solid #D6DFF6; 
    border-radius: 20px;
    width: 20px; 
    height: 20px; 
    background-color: #FFFFFF; 
    opacity: 1; 
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}
.radio-group-icon.active {
    border: 4px solid #D6DFF6; 
    background-color: #3751FF;
}
.radio-group-text {
    font-size: 14px;
    color: #212633;
}
.zoom-icon {
    position: absolute;
    top: 28px;
    right: 100px;
    border-radius: 44px;
    height: 44px;
    width: 44px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.activity-tab {
    padding: 0px 75px;
}
.activity-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 20px;
    background: #F5F5F5;
    border: 1px solid #F0F0F0;
}
.activity-text {
    font-size: 14px;
    font-weight: 500;
}
.activity-content {
    border: 1px solid #F0F0F0;
    overflow: auto;
    padding-left: 14px;
    padding-right: 14px;

    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    height: 224px;
}
.activity-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.activity-item > .text-medium {
    min-width: 42px;
}
.overlap-nodata {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.48);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px solid #F0F0F0;
}
.pin-content {
    position: absolute;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
    cursor: pointer;
    gap: 14px;
}
.pin-content-name {
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 133%;
    color: #3751FF;
}
.status-tab {
    padding: 12px;
    border: 1px solid #EFF1F1;
    border-radius: 8px;
    margin-left: 157px;
    margin-right: 157px;
}
.status-content-table {
    height: 175px;
    overflow: auto;
}
.status-header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}
.status-text {
    font-weight: 700;
    font-size: 14px;
    color: #262626;
}
.status-input {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid #D9D9D9;
    outline: none;
    width: 76px;
    padding-top: 8px;
    padding-bottom: 7px;
    color: #262626;
}
.traffic-list {
    max-height: 420px;
    overflow-x: hidden;
    margin-bottom: 16px;
    padding-right: 8px;
}
.bg-traffic {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.success-content {
    display: flex;
    align-items: center;
    width: 500px;
    background-color: #FFFFFF;
    position: absolute;
    right: 35px;
    top: 93px;
    border-left: 4px solid #33C963;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 16px;
}
.success-text {
    font-weight: 400;
    font-size: 14px;
    color: #707070;
    margin-left: 16px;
}
.polygon-content {
    position: absolute;
    right: 33px;
    top: 80px;
    z-index: 10000;
}
.polygon-content-name {
    position: absolute;
    right: 158px;
    top: 80px;
}
.controlpanel-edit {
    position: absolute;
    left: 35px;
    top: 29px;
    display: flex;
    flex-direction: row;
    gap: 12px;
}
.controlpanel-edit-content {
    width: 104px;
    height: 48px;
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}
.controlpanel-edit-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
}
.traffic-timestamp {
    height: 82px;
    background-color: #F0F0F0;
    padding-top: 6px;
    width: 100%;
}
.traffic-timestamp-text {
    padding: 16px 14px;
    color: #262626;
    font-weight: 400;
    font-size: 12px;
    background-color: #fff;
    height: 100%;
}
.icon-setting {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.icon-setting.active {
    background: #3751FF;
}
.icon-location {
    width: 52px;
    height: 48px;
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
    cursor: pointer;
}
.icon-location-arrow {
    width: 8px;
    height: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.right-tool {
    position: absolute;
    right: 35px;
    top: 27px;
    display: flex;
    gap: 12px;
}

.dropdown-polygon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 319px;
}
.dropdown-polygon-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px;
    width: 319px;
    height: auto;
    background: #FFFFFF;
}
.dropdown-polygon-item {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    width: 319px;
    height: 49px;
    background: #FFFFFF;
    justify-content: start;
    cursor: pointer;
    font-weight: 500;
}
.dropdown-polygon-item:hover {
    background: #F8F9FC;
    color: #3751FF;
}
.dropdown-polygon-arrow {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.dropdown-polygon-icon {
    height: 21px;
    width: 21px;
    clip-path: polygon(50% 0, 0% 100%, 100% 100%);
    background-color: #FFFFFF;
}
.config-bg-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 58px 38px;
    position: absolute;
    width: 620px;
    height: auto;
    background: #FFFFFF;
    border-radius: 8px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
}
.config-time-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    gap: 24px;
    z-index: 10000;
}
.config-time-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #3751FF;
}
.polygon-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 16px;
    width: 319px;
    height: 49px;
    background: #FFFFFF;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.polygon-item:hover {
    background: #E6F7FF;
}
.plus-icon {
    flex-shrink: 0;
    height: 44px;
    width: 44px;
    background-color: #0979FD;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 44px;
    cursor: pointer;
}
