.table-role-setting-systemsettingpage{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0px;
    padding-right: 10px;
    width: 954px;
    height: 581px;

    border: 1px solid #EFF1F1;
    border-radius: 8px;
}
.input-checkbox-systemsettingpage{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 30px;
    width: 100%;
    background: #FFFFFF;
}
.input-checkbox-systemsettingpage label {
    margin: 0;
}
.label-title-systemsettingpage{
    
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    height: 19px;
    text-align: center;
    color: #172B4D;
}