.tab-bar-date-week-strategypage {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 14px;
    gap: 10px;
    width: 954px;
    height: 67px;
    border: 1px solid #EFF1F1;
    border-radius: 8px;
}

.item-tab-bar-date-week-strategypage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 34px;
    gap: 10px;
    /* width: 119px; */
    height: 43px;
    background: #76A9FA;
    border-radius: 200px;
    min-width: 95px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
}
.item-tab-bar-date-week-strategypage.active {
    background: #3751FF;
}

.list-strategy-date-strategypage {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 24px;
    width: 352px;
    height: 490px;
    background: #FFFFFF;
    border: 1px solid #EFF1F1;
    border-radius: 8px;
}

.item-in-list-strategy-date-strategypage {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 10px;
    /* gap: 7px; */
    width: 302px;
    height: 48px;
    background: #F5F7F9;
    border-radius: 4px;
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
.item-in-list-strategy-date-strategypage.active {
    border: 1px solid #0050B3;
    background: #F0F5FF;
}

.table-strategy-date-strategypage {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 582px;
    height: 100%;
    border: 1px solid #EFF1F1;
    border-radius: 8px;
}

.title-table-strategy-date-strategypage {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #3751FF;
}

.item-add-srtategy-date-strategypage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px;
    max-width: 100%;
    background-color: #FAFAFA;
    border: 1px dashed #3751FF;
    /* margin: 10px; */
    /* margin: 10px 24px 10px 24px; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #3751FF;
}
.item-detail-srtategy-date-strategypage .timer-content-label {
    color: #3751FF;
}
.item-detail-srtategy-date-strategypage {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px 10px 80px;
    gap: 20px;
    width: 100%;
    background: #FFFFFF;
    justify-content: space-between;
    background: #F0F5FF;
    border: 1px dashed #003A8C;
    border-radius: 4px;
    font-size: 14px;
}
.strategy-time-group {
    display: flex;
    align-items: center;
    gap: 130px;
}
.item-detail-srtategy-date-strategypage.empty {
    background: #FAFAFA;
    border: 1px dashed #D9D9D9;
}
.item-detail-srtategy-date-strategypage.empty .strategy-time-close {
    visibility: hidden;
}
.item-detail-srtategy-date-strategypage.empty .strategy-time-clock {
    pointer-events: none;
}
.header-strategy-time {
    display: flex;
    padding: 12px 20px;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    background-color: #FAFAFA;
    border-bottom: 1px solid #F0F0F0;
}
