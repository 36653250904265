.item-station-controlpanelpage {
    display: flex;
    flex-direction: column;
    padding: 10px;
    /* width: 290px; */
    background: #F5F7F9;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 14px;
    border: 1px solid #F0F0F0;
}
.item-station-controlpanelpage.active {
    border: 1px solid #0050B3;
    background: #F0F5FF;
}
.item-station-controlpanelpage:last-child {
    margin-bottom: 0px;
}

.item-title-station-controlpanelpage {
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    color: #172B4D;
    text-align: left;
}
.item-search-map-bar-expanded-controlpanelpage {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 0px;
    gap: 12px;
    position: absolute;
    width: 454px;
    height: 44px;
    top: 28px;
    right: 106px;
    background: #FFFFFF;
    /* border: 1px solid #F5F6F6; */
    border-radius: 45px;
    border-width: 1px;
    border-style: solid;
    border-color: #FFFFFF;
}
.active {
    border-color: #1890FF;
}

.item-search-map-bar-expanded-controlpanelpage:focus {
    border: 1px solid #1890FF;
}

.item-search-map-bar-colapse-controlpanelpage{
    position: absolute;
    top: 28px;
    right: 36px;
    border-radius: 44px;
    height: 44px;
    width: 44px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.input-placerhold-station-search-controlpanelpage {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px 12px 16px;
    caret-color: #3751FF;
    height: 44px;
    background-color: rgba(55, 81, 255, 0.06);
    border: none;
    border-radius: 4px;
    gap: 10px;
    width: 674px;

}

.input-placerhold-station-search-controlpanelpage:focus {
    outline: none;
    border-color: #3751FF;
    /* border-width: 1px; */
}

.item-setting-station-controlpanelpage {
    /* display: flex;
    flex-direction: column; */
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    width: 924px;
    height: 342px;
    left: 30px;
    bottom: 13px;
    /* padding: 18px 16px; */
    background: #FFFFFF;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    z-index: 10000;
}

.table-cpu-infor-controlpanel{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px 10px;
    gap: 14px;
    width: 419.5px;
    height: 248px;
    background: #FFFFFF;
    border: 1px solid #EFF1F1;
    border-radius: 8px;
}
.table-channel-output-controlpanel{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px 10px;
    gap: 14px;
    width: 419.5px;
    /*height: 220px;*/
    background: #FFFFFF;
    border: 1px solid #EFF1F1;
    border-radius: 8px;
}

.bottom-sheet-add-station-controlpanel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 28px 18px;
    /* gap: 44px; */
    width: 620px;
    /* height: 380px; */
    background: #FFFFFF;
    border-radius: 8px;
    margin: auto;
}

.title-overview-info-controlpanel {
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: right;
    color: #172B4D;
    width: 142px;
}

.input-form-controlpanel {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;
    caret-color: #3751FF;
    height: 48px;
    border-radius: 4px;
    background: rgba(250, 250, 250, 0.6);
    border: 1px solid #D9D9D9;
}
.input-form-controlpanel:focus {
    outline: none;
    border-color: #3751FF;
    border-width: 1px;
}
.input-form-controlpanel:disabled {
    background: #F5F5F5;
}

.selector-form-controlpanel {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 8px;
    width: 467px;
    background: #E6E9FD;
    border: 1px solid #D6DFF6;
    border-radius: 4px;
}
.item-station-name {
    font-weight: 500;
    font-size: 14px;
}
.item-station-subname {
    font-size: 14px;
    color: #595959;
}
.station-status {
    color: #49D009;
    border: 1px solid #49D009;
    border-radius: 4px;
    padding: 4px 8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 1;
}
.station-status.off {
    color: #F7151A;
    border-color: #F7151A;
}
.station-navs {
    background-color: #F5F5F5;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: none;
}
.station-navs .nav-item {
    border: none;
    padding: 16px 24px;
    font-weight: 500;
    font-size: 14px;
    color: #8C8C8C;
    border-radius: 0;
}
.station-navs .nav-item.active {
    border-top-left-radius: 8px;
    color: #3751FF;
}
.info-title {
    color: #3751FF;
    font-weight: 600;
    font-size: 18px;
}
.info-group-item {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}
.info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 18px;
    border: 1px solid #EFF1F1;
    border-radius: 2000px;
}
.info-item-left {
    color: #172B4D;
    font-weight: 500;
    font-size: 14px;
}
.info-item-right {
    color: #3751FF;
    font-weight: 500;
    font-size: 14px;
}
.info-text-green {
    color: #52C41A;
    font-weight: 400;
    font-size: 14px;
}
.close-modal {
    position: absolute;
    top: 18px;
    right: 17px;
    cursor: pointer;
}
