.drawer-option {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 20px;
}
.drawer-option-profilepage{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0px 16px 0px;
    width: 340px;
    height: 814px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}
.content-table-profilepage{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 16px;
    width: 986px;
    height: 814px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}
.drawer-option-profilepage-avatar {
    width: 158px;
    height: 158px;
    /*background-color: gray;*/
    box-sizing: border-box;
    border: 1px solid #3751FF;
    border-radius: 16px;
    display: block;
    position: relative;
    margin-bottom: 24px;
}
.drawer-option-profilepage-avatar-image {
    position: absolute;
    right: 10px;
    bottom: 8px;
    background-color: #FFFFFF;
    opacity: 0.8;
    width: 34px;
    height: 34px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
