.input-label {
  color: #9FA2B4;
  font-size: 14px;
  line-height: 14.7px;
  font-weight: 700;
}
.input-form-loginpage {
  width: 100%;
  height: 48px;
  display: block;
  background-color: #FCFDFE;
  color: #4B506D;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border-color: #F0F1F7;
  border-width: 1px;
  border-radius: 4px;
  padding: 14px 16px;
  margin-top: 6px;
  margin-bottom: 24px;
}
.input-form-loginpage:focus {
  width: 100%;
  height: 48px;
  display: block;
  background-color: #FCFDFE;
  color: #4B506D;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border-color: #3751FF;
  border-width: 1px;
  border-radius: 4px;
  padding: 14px 16px;
  margin-top: 6px;
  margin-bottom: 24px;
  box-shadow: none;
}
