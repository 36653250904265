.item-notify-header{
     width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
}
.item-notify-id{
     height: 16px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 133%;
                    text-align: start;
                    color: #3751FF
}
.item-notify-time{
                    height: 16px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 133%;
                    text-align: end;
                    color: #8C8C8C;
                    white-space:nowrap;
}
.item-notify-description{
    color: #595959 ;
    line-height: 133%;
    font-style:normal;
    font-weight:400;
    font-size:14px;
    margin-bottom:0
}