.container-center {
    text-align: center;
    width: auto;
    margin-left: 0em;
    margin-right: 0em;
    margin-top: 0.25rem;
}

.box {
    /*position: absolute;*/
    width: 36em;
    height: 25em;
    /*left: 686px;*/
    /*top: 345px;*/
    padding: 2em;
    margin: auto;

    background: rgba(120, 119, 119, 0.5);
    border-radius: 32px;
}
.cursor-pointer {
    cursor: pointer;
}
.pl-18 {
    padding-left: 18px;
}
.ml-20 {
    margin-left: 20px;
}
.mr-12 {
    margin-right: 12px;
}
.mr-20 {
    margin-right: 20px;
}
.color-green {
    color: #3751FF;
}
.text-medium {
    font-weight: 500;
}
.color-dust-red {
    color: #F5222D;
}
.mw-200 {
    min-width: 200px;
}
.font-14 {
    font-size: 14px;
}
.color-text-base {
    color: #172B4D;
}
.p-10-20 {
    padding: 10px 20px !important;
}
.pr-100 {
    padding-right: 100px !important;
}
.h-406 {
    height: 406px !important;
}
.high-light-text {
    background-color: #FFEC3D;
}
.w-272 {
    width: 272px;
}
.w-467 {
    width: 467px;
}
.gray-5 {
    background: rgba(250, 250, 250, 0.6);
}

@media(min-width: 426px) {
    .box {
        padding: 2em 4.5em;
    }
}

.form-label {
    color: aliceblue;
    font-size: 1.3rem;
    text-align: center;
}

.submit-button {
    width: 100%;
    margin-top: 1em;
    align-content: center;
}

.gdot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    align-content: center;
    margin: 0;
    padding: 0;
    /*display: inline-block;*/
}